import './bootstrap'
import '../css/app.css'

import DashboardLayout from '@/Layouts/Dashboard.vue'
import WebsiteLayout from '@/Layouts/WebsiteLayout.vue'

import { createApp, h, DefineComponent } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from '../../vendor/tightenco/ziggy'

import money from 'v-money3'
import 'vue-toast-notification/dist/theme-sugar.css'

createInertiaApp({
    title: (title) => `${title}`,
    resolve: (name) => {
        const page = resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob<DefineComponent>('./Pages/**/*.vue')
        )

        if (name.indexOf('Terms') > -1 || name.indexOf('Samuel') > -1) {
            return page
        }

        if (name.startsWith('Admin/') || name.startsWith('Affiliate/') || name.startsWith('Client/') ||
            name.startsWith('Agency/')) {
            page.then((module) => {
                module.default.layout = module.default.layout || [
                    DashboardLayout,
                ]
            })
        } else if (name.startsWith('Website/')) {
            page.then((module) => {
                module.default.layout = module.default.layout || [
                    WebsiteLayout,
                ]
            })
        }

        return page
    },
    setup({ el, App, props, plugin }) {
        createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(money)
            .use(ZiggyVue)
            .mount(el)
    },
    progress: {
        color: '#4B5563',
    },
})

<script setup lang="ts">
import { router, Link, usePage } from '@inertiajs/vue3'
import { checkPermission } from '@/Helpers/Auth'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import { 
    Dashboard, 
    Users, 
    Barcode, 
    Affiliate,
    Search, 
    User,
    Settings, 
    Power, 
    Ticket,
    Bell,
    Calendar,
    Moon,
    Sun
} from '@vicons/tabler'
import { AttachMoneyRound, MenuRound, CloseRound, AccountBalanceWalletOutlined, MiscellaneousServicesFilled, NotificationAddRound, EditNoteSharp } from '@vicons/material'
import { TreeViewAlt, BareMetalServer } from '@vicons/carbon'
import { PricetagsOutline } from '@vicons/ionicons5'

import { currencyFormat } from '@/Helpers/Utils'

const props = defineProps({
    services: Object
})

const mode = ref('light')
provide('mode', mode)


const toggleMode = () => {
    mode.value = (mode.value == 'light') ? 'dark' : 'light'
    router.post(route('admin.toggle.dashboardmode'));
}

const loggedAdmin = computed(() => usePage().props.loggedAdmin)
const adminLogged = computed(() => usePage().props.auth.adminLogged)
const agencyUserLogged = computed(() => usePage().props.agencyUserLogged)

const user = computed(() => usePage().props.auth.user)
const notifications = computed(() => usePage().props.notifications)
const profileOpened = ref(false)
const breakpoints = useBreakpoints(breakpointsTailwind)

const searchQuery = ref('')
const showNotifications = ref(false)
const mdOrSmaller = breakpoints.smallerOrEqual('lg')
const openedMenuMobile = ref(false)

const toggleMobileMenu = () => {
    openedMenuMobile.value = !openedMenuMobile.value
}

const defineMobileMenu = computed(() => {
    let classes = ''
    if (mdOrSmaller.value) {
        classes =  (openedMenuMobile.value) ? 'mobile opened' : 'mobile closed'
    }

    if (mode.value == 'dark') {
        classes += ' tw-bg-darkmode-box'
    } else {
        classes += ' tw-bg-white'
    }
    //return null

    return classes
})

const searchClasses = computed(() => {
    let classes = (['affiliate', 'client', 'agency'].includes(user.value.role)) ? 'tw-justify-end' : ''

    if (mode.value == 'dark') {
        classes = classes+' tw-bg-darkmode-box'
    } else {
        classes = classes+' tw-bg-white'
    }

    return classes
})

const toggleNotifications = () => {
    showNotifications.value = (showNotifications.value) ? false : true
}

const toggleProfile = () => {
    profileOpened.value = (profileOpened.value) ? false : true
}

const goToProfile = () => {
    router.visit(route('admin.profile'))
}

const executeSearch = () => {
    if (String(searchQuery.value).trim() !== '') {
        router.visit(route('admin.search'), {
            data: {
                s: searchQuery.value
            },
            onFinish: () => {
                searchQuery.value = ''
            }
        })
    }
}

const accessUserAccount = () => {
    router.post(route('admin.user.access.account'), {}, {
        onSuccess: () => {
            window.location.href= '/admin'
        }
    })
}

const logout = () => {
    router.visit('/logout', {
        method: 'post',
        replace: true,
        preserveState: false,
    })
}


//refresh do usuario a cada 5 minnutos pra evitar perder a sessão etc
const inactivityTime = 300000 // 5 minutos
const timer = ref(null)

const refresh = () => {
    //router.visit(route('admin.index'))
}

const resetTimer = () => {
    if (timer.value) {
        clearTimeout(timer.value)
    }
    timer.value = setTimeout(refresh, inactivityTime)
}

const setupEventListeners = () => {
    window.addEventListener('load', resetTimer)
    window.addEventListener('mousemove', resetTimer)
    window.addEventListener('keypress', resetTimer)
    window.addEventListener('scroll', resetTimer)
    window.addEventListener('click', resetTimer)
}

const removeEventListeners = () => {
    window.removeEventListener('load', resetTimer)
    window.removeEventListener('mousemove', resetTimer)
    window.removeEventListener('keypress', resetTimer)
    window.removeEventListener('scroll', resetTimer)
    window.removeEventListener('click', resetTimer)
};

const modalPricing = ref(false)
const modalPricingAgency = ref(false)
const serviceResourcesAmount = ref(0)

const available_rules = {
    until: 'Intervalo',
    greater_than: 'Maior que',
    greater_equal: 'Maior ou igual',
    equal: 'Igual a'
}

const showResourceTitle = (resource, amount) => {
    if (resource == 'storage_time') {
        return amount + ' dias de armazenamento'
    } else if (resource == 'instances_amount') {
        return amount + ' instancias'
    } else if (resource == 'users_amount') {
        return amount + ' usuários'
    } else if (resource == 'attendants_amount') {
        return amount + ' atendentes'
    }
}


onMounted(() => {
    setupEventListeners()
    resetTimer()
})

onUnmounted(() => {
    removeEventListeners()
})

const logo = ref(null)

onBeforeMount(() => {

    mode.value = user.value.dashboard_mode

    if (props.services) {
        props.services.map((row) => {

            row.resources.map((item) => {
                if (item.price) {
                    serviceResourcesAmount.value += Number(item.price)
                }
            })
        })
    }

    if (user.value.role == 'agency') {
        logo.value = (user.value.logo) ? user.value.logo : '/images/logo-placeholder.png'
    } else {
        if (user.value.agency_owners.length > 0) {
            //não sendo uma agencia entao vai usar a logo da agencia que cadatrou o cliente
            const last = user.value.agency_owners.length - 1;
            const lastOwnerLogo = user.value.agency_owners[last].logo

            if (lastOwnerLogo) {
                logo.value = lastOwnerLogo
            }
        }
    }
})
</script>

<template>
    <Modal 
        v-if="modalPricingAgency && user.agency_owners.length == 0 && user.role == 'agency'"
        @close-modal="modalPricingAgency=false"
        title="Precificação"
        class="tw-w-[40%]"
    >
        <section class="tw-w-full tw-flex tw-gap-3 tw-flex-wrap">
            <Box 
                :header="{
                    title: 'Precificação Multi V2'
                }"
                class="tw-shadow-none"
            >
                <section class="tw-w-full tw-flex tw-flex-wrap tw-gap-3 tw-p-4 tw-pt-0">

                    <div class="tw-w-full">
                        <h3 class="tw-w-full tw-flex tw-justify-between tw-items-center">
                            <span class="tw-font-bold tw-text-slate-500">Precificação de pacotes</span>
                        </h3>
                        <div class="tw-w-full tw-p-3 tw-rounded-md tw-bg-sky-200 tw-text-sky-500 tw-my-3">
                            Valor do pacote pra quantidade de empresas cadastradas
                        </div>

                        <div 
                            class="tw-w-full tw-flex tw-flex-wrap tw-relative tw-mb-2"
                            v-for="(rule, key) in user.agency_service_pricing.pricing_multiv2.packages" 
                            :key="key"
                        >

                            <div class="tw-w-full tw-flex tw-gap-3">
                                <div class="tw-w-[20%]">
                                    <FormItem class="tw-w-full" label="Tipo">
                                        <span class="pseudo-input">{{ available_rules[rule.modifier] }}</span>
                                    </FormItem>
                                </div>

                                <div v-if="rule.modifier == 'until'" class="tw-w-[80%] tw-gap-2 tw-flex">
                                    <FormItem class="tw-w-1/3" label="De">
                                        <span class="pseudo-input">{{ rule.values.from }}</span>
                                    </FormItem>

                                    <FormItem class="tw-w-1/3" label="Até">
                                        <span class="pseudo-input">{{ rule.values.to }}</span>
                                    </FormItem>

                                    <FormItem class="tw-w-1/3" label="Preço">
                                        <span class="pseudo-input">{{ currencyFormat(rule.amount) }}</span>
                                    </FormItem>
                                </div>

                                <div v-else class="tw-w-[80%] tw-gap-2 tw-flex">
                                    <FormItem class="tw-w-1/2" label="Valor">
                                        <span class="pseudo-input">{{ rule.value }}</span>
                                    </FormItem>

                                    <FormItem class="tw-w-1/2" label="Preço">
                                        <span class="pseudo-input">{{ currencyFormat(rule.amount) }}</span>
                                    </FormItem>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="tw-w-full">
                        <h3 class="tw-w-full tw-flex tw-justify-between tw-items-center">
                            <span class="tw-font-bold tw-text-slate-500">Precificação de armazenamento</span>
                        </h3>

                        <div class="tw-w-full tw-p-3 tw-rounded-md tw-bg-sky-200 tw-text-sky-500 tw-my-3">
                            Valor do armazenamento baseado no numero de dias
                        </div>

                        <div 
                            class="tw-w-full tw-flex tw-flex-wrap tw-relative tw-mb-2"
                            v-for="(rule, key) in user.agency_service_pricing.pricing_multiv2.storage" :key="key"
                        >

                            <div class="tw-w-full tw-flex tw-gap-3">
                                <div class="tw-w-[20%]">
                                    <FormItem class="tw-w-full" label="Tipo">
                                        <span class="pseudo-input">{{ available_rules[rule.modifier] }}</span>
                                    </FormItem>
                                </div>

                                <div v-if="rule.modifier == 'until'" class="tw-w-[80%] tw-gap-2 tw-flex">
                                    <FormItem class="tw-w-1/3" label="De">
                                        <span class="pseudo-input">{{ rule.values.from }}</span>
                                    </FormItem>

                                    <FormItem class="tw-w-1/3" label="Até">
                                        <span class="pseudo-input">{{ rule.values.to }}</span>
                                    </FormItem>

                                    <FormItem class="tw-w-1/3" label="Preço">
                                        <span class="pseudo-input">{{ currencyFormat(rule.amount) }}</span>
                                    </FormItem>
                                </div>

                                <div v-else class="tw-w-[80%] tw-gap-2 tw-flex">
                                    <FormItem class="tw-w-1/2" label="Valor">
                                        <span class="pseudo-input">{{ rule.value }}</span>
                                    </FormItem>

                                    <FormItem class="tw-w-1/2" label="Preço">
                                        <span class="pseudo-input">{{ currencyFormat(rule.amount) }}</span>
                                    </FormItem>
                                </div>
                            </div>
                        </div>

                        <FormItem label="Valor extra por dia ao exceder limite" class="tw-w-full">
                            <span class="pseudo-input">{{ currencyFormat(user.agency_service_pricing.pricing_multiv2.storage_extra_amount) }}</span>
                        </FormItem>
                    </div>

                </section>

                

                <div class="tw-w-full tw-flex tw-gap-3 tw-p-4">
                    <FormItem label="Valor por instancia" class="tw-w-1/2">
                        <span class="pseudo-input">{{ currencyFormat(user.agency_service_pricing.pricing_multiv2.instance_amount) }}</span>
                    </FormItem>

                    <FormItem label="Valor por atendentes" class="tw-w-1/2">
                        <span class="pseudo-input">{{ currencyFormat(user.agency_service_pricing.pricing_multiv2.attendant_amount) }}</span>
                    </FormItem>
                </div>
            </Box>

            <Box 
                :header="{
                    title: 'SAC',
                    subtitle: 'Precificação por usuarios'
                }"
                
                class="tw-shadow-none"
            >
                <section class="tw-w-full tw-p-4 tw-pt-0">
                    <FormItem 
                        class="tw-w-full" 
                        label="Preço"
                    >
                        <span class="pseudo-input">
                            {{ currencyFormat(user.agency_service_pricing.pricing_sac.amount) }}
                        </span>
                    </FormItem>
                </section>
            </Box>

            <Box 
                :header="{
                    title: 'API Desenvolvimento',
                    subtitle: 'Precificação instancias'
                }"
                
                class="tw-shadow-none"
            >
                <section class="tw-w-full tw-p-4 tw-pt-0">
                    <FormItem class="tw-w-full" label="Preço">
                        <span class="pseudo-input">
                            {{ currencyFormat(user.agency_service_pricing.pricing_integracao.amount) }}
                        </span>
                    </FormItem>
                </section>
            </Box>

            <Box 
                :header="{
                    title: 'Demais serviços',
                    subtitle: 'Precificação por conta'
                }"
                
                class="tw-shadow-none"
            >
                <section class="tw-w-full tw-p-4 tw-pt-0">
                    <FormItem class="tw-w-full" label="Preço">
                        <span class="pseudo-input">
                            {{ currencyFormat(user.agency_service_pricing.pricing_others.amount) }}
                        </span>
                    </FormItem>
                </section>
            </Box>
        </section>
    </Modal>

    <Modal 
        v-if="modalPricing && user.agency_owners.length == 0 && user.role == 'client'"
        @close-modal="modalPricing=false"
        title="Precificação"
        class="tw-w-[40%]"
    >
        <div 
            class="tw-w-full tw-flex tw-flex-wrap tw-p-2 tw-border-[1px] tw-border-slate-300 tw-mb-3"
            v-for="(service, key) in services"
            :key="key"
        >
            <h2 class="tw-w-full tw-font-bold tw-text-slate-600 tw-flex tw-justify-center">
                <span v-if="service.key == 'multiv2'">{{service.title}} - {{ service.metadata.company_name }}</span>
                <span v-else>{{service.title}}</span>
            </h2>

            <table>
                <thead>
                    <tr>
                        <th>Recurso</th>
                        <th>Valor</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(resource, keyR) in service.resources" :key="keyR">
                        <td>{{ showResourceTitle(resource.resource, resource.amount) }}</td>
                        <td>{{ currencyFormat(resource.price) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <table>
            <tr>
                <td>Total</td>
                <td>{{ currencyFormat(serviceResourcesAmount) }}</td>
            </tr>
        </table>
    </Modal>

    <section 
        class="tw-w-full tw-flex tw-flex-col tw-min-h-screen dashboard-wrapper"
        :class="(mode == 'dark') ? 'darkmodeon tw-bg-darkmode' : 'darkmodeoff tw-bg-[#F5F5F9]'"
    >
        <section class="tw-flex tw-flex-1 tw-w-full">

            <aside
                class="menu"
                :class="defineMobileMenu"
            >
                <div
                    class="tw-absolute tw-top-[10px] tw-right-[-50px] tw-w-[40px] tw-h-[40px] tw-bg-white tw-rounded-md tw-shadow-md 
                tw-flex lg:tw-hidden tw-justify-center tw-items-center tw-cursor-pointer"

                    @click="toggleMobileMenu"
                >
                    <CloseRound class="tw-w-5 tw-text-slate-500" />
                </div>

                <section class="tw-w-full scroller tw-overflow-y-auto tw-h-full tw-p-3">
                    <div class="tw-w-full tw-inline-flex tw-justify-center tw-my-4">
                        <img
                            :src="logo"
                            v-if="logo"
                            class="tw-p-0 tw-object-fill"
                        >
                        <h2 class="tw-text-[25px] tw-font-bold" v-else>
                            <span class="tw-text-blue-wc">Whats.</span>
                            <span class="tw-text-green-wc">Company</span>
                        </h2>
                    </div>

                    <ul
                        class="menu-list scroller"
                        v-if="user.role == 'agency'"
                    >
                        <MenuItem
                            :icon="Dashboard"
                            :path="`/admin`"
                        >
                            Dashboard
                        </MenuItem>
                        
                        <MenuItem
                            :icon="Users"

                            :items="[
                                {label: 'Novo Cliente', path: route('admin.agencies.clients.create'), show: user.blocked_services == 0 && user.status == 'active'},
                                {label: 'Ver Clientes', path: route('admin.agencies.clients.index')}
                            ]"
                        >
                            Clientes
                        </MenuItem>

                        <MenuItem
                            :icon="Barcode"
                            :path="route('admin.agencies.partialcharges')"
                            v-if="user.agency_owners.length == 0"
                        >
                            Fatura Parcial
                        </MenuItem>

                        <MenuItem
                            :icon="Ticket"
                            :path="`/admin/tickets`"
                            v-if="user.agency_owners.length == 0"
                        >
                            Meus Tickets
                        </MenuItem>

                        <MenuItem
                            :icon="Barcode"
                            :path="route('admin.client.clientPayments')"
                            v-if="user.agency_owners.length == 0 || (user.agency_owners.length == 1 && user.agency_owners[0].agency_automaticcharge_feature == 1)"
                        >
                            Pagamentos
                        </MenuItem>

                        <!--- só se nao for agencia de agencia -->
                        <MenuItem
                            :icon="Users"

                            v-if="user.agency_owners.length == 0"
                            :items="[
                                {label: 'Nova Revenda', path: route('admin.agencies.agency.create'), show: user.blocked_services == 0 && user.status == 'active'},
                                {label: 'Ver Revendas', path: route('admin.agencies.agency.index')}
                            ]"
                        >
                            Revendas
                        </MenuItem> 
                        
                        <MenuItem
                            :icon="Users"

                            v-if="!agencyUserLogged"
                            
                            :items="[
                                {
                                    label: 'Novo usuário',
                                    path: route('admin.agencies.team.user.create'),
                                    show: user.blocked_services == 0 && user.status == 'active'
                                },
                                {label: 'Ver usuários', path: route('admin.agencies.team.users')}
                            ]"
                        >
                            Equipe
                        </MenuItem>

                        <MenuItem
                            :icon="PricetagsOutline"
                            v-if="user.agency_owners.length == 0"
                            action="openPricing"
                            @openPricing="modalPricingAgency=true"
                        >
                            Precificação
                        </MenuItem>
                    </ul>

                    <ul
                        class="menu-list scroller"
                        v-if="user.role == 'client'"
                    >
                        <MenuItem
                            :icon="Dashboard"
                            :path="`/admin`"
                        >
                            Dashboard
                        </MenuItem>

                        <MenuItem
                            :icon="Ticket"
                            :path="`/admin/tickets`"
                            v-if="user.agency_owners.length == 0"
                        >
                            Meus Tickets
                        </MenuItem>


                        <MenuItem
                            :icon="Barcode"
                            :path="route('admin.client.clientPayments')"
                            v-if="user.agency_owners.length == 0 || (user.agency_owners.length == 1 && user.agency_owners[0].agency_automaticcharge_feature == 1)"
                        >
                            Pagamentos
                        </MenuItem>

                        <MenuItem
                            :icon="AccountBalanceWalletOutlined"
                            path="/admin/clients/credits"
                            v-if="user.agency_owners.length == 0"
                        >
                            Créditos
                        </MenuItem>

                        <MenuItem
                            :icon="MiscellaneousServicesFilled"
                            path="/admin/clients/service-changes"
                            v-if="user.agency_owners.length == 0"
                        >
                            Mudanças em Serviços
                        </MenuItem>

                        <MenuItem
                            :icon="PricetagsOutline"
                            v-if="user.agency_owners.length == 0"
                            action="openPricing"
                            @openPricing="modalPricing=true"
                        >
                            Precificação
                        </MenuItem>
                    </ul>

                    <ul
                        class="menu-list scroller"
                        v-if="user.role == 'affiliate'"
                    >
                        <MenuItem
                            :icon="Dashboard"
                            :path="`/admin`"
                        >
                            Dashboard
                        </MenuItem>

                        <MenuItem
                            :icon="Users"
                            :path="route('admin.affiliates.myClients')"
                        >
                            Meus Clientes
                        </MenuItem>

                        <MenuItem
                            :icon="Ticket"
                            :path="`/admin/tickets`"
                        >
                            Meus Tickets
                        </MenuItem>

                        <MenuItem
                            :icon="AttachMoneyRound"
                            :path="route('admin.affiliates.transfers')"
                        >
                            Meus Saques
                        </MenuItem>
                    </ul>

                    <ul
                        class="menu-list scroller"
                        v-if="user.role == 'admin' || user.role == 'superadmin'"
                    >
                        <MenuItem
                            :icon="Dashboard"
                            :path="`/admin`"
                        >
                            Dashboard
                        </MenuItem>

                        <MenuItem
                            :icon="Calendar"
                            :path="route('admin.schedules', {
                                type: 'comercial'
                            })"
                        >
                            Agenda Comercial
                        </MenuItem>

                        

                        <MenuItem
                            :icon="Calendar"
                            :path="route('admin.schedules', {
                                type: 'personal'
                            })"
                        >
                            Agenda Pessoal
                        </MenuItem>

                        <MenuItem
                            :icon="Users"
                            :items="[
                                {label: 'Nova agencia', path: route('admin.agencies.create'), show: checkPermission(user, ['UserPolicy:create:agency'], true)},
                                {label: 'Ver agencias', path: route('admin.agencies.index'), show: checkPermission(user, ['UserPolicy:viewAny,view:agency'], true)},
                                {label: 'Precificação', path: route('admin.agencies.pricing'), show: (user.role == 'superadmin')}
                            ]"

                            v-if="checkPermission(user, [
                                'UserPolicy:viewAny,view:agency',
                                'UserPolicy:create:agency'
                            ], false)"
                        >
                            Agências
                        </MenuItem>

                        <MenuItem
                            :icon="EditNoteSharp"
                            :path="route('admin.notes')"
                        >
                            Bloco de notas
                        </MenuItem>

                        <MenuItem
                            :icon="Ticket"
                            :path="route('admin.coupons.index')"
                            v-if="user.role == 'superadmin'"
                        >
                            Cupons
                        </MenuItem>
                        
                        <MenuItem
                            :icon="Users"
                            v-if="checkPermission(user,[
                                'UserPolicy:viewAny,view:client',
                                'UserPolicy:create:client'
                            ],false)"
                            :items="[
                                {label: 'Novo Cliente', path: '/admin/clients/create', show: checkPermission(user, ['UserPolicy:create:client'], true)},
                                {label: 'Ver Clientes', path: '/admin/clients', show: checkPermission(user, ['UserPolicy:viewAny,view:client'], true)},
                                {label: 'Precificação', path: route('admin.client.pricing'), show: (user.role == 'superadmin')}
                            ]"
                        >
                            Clientes
                        </MenuItem>

                        <MenuItem
                            :icon="NotificationAddRound"
                            :path="route('admin.client.notifications')"
                            v-if="user.role == 'superadmin'"
                        >
                            Notificar em massa
                        </MenuItem>

                        <MenuItem
                            :icon="BareMetalServer"
                            :path="`/admin/servers`"
                            v-if="user.role == 'superadmin'"
                        >
                            Servidores
                        </MenuItem>

                        <MenuItem
                            :icon="Ticket"
                            :path="`/admin/tickets`"
                        >
                            Tickets
                        </MenuItem>

                        


                        <li 
                            class="menu-division"
                            v-if="checkPermission(user,[
                                'UserPolicy:viewAny,view:affiliate',
                                'UserPolicy:create:affiliate',
                                'DepartmentPolicy:viewAny,view',
                                
                                'UserPolicy:viewAny,view:admin',
                                'UserPolicy:create:admin'
                            ])"
                        >
                            Gestão de Usuários
                        </li>

                        <MenuItem
                            :icon="Affiliate"
                            :items="[
                                {label: 'Cadastrar novo', path: '/admin/affiliates/create', show: checkPermission(user, ['UserPolicy:create:affiliate'], true)},
                                {label: 'Ver afiliados', path: '/admin/affiliates', show: checkPermission(user, ['UserPolicy:viewAny,view:affiliate'], true)},
                            ]"

                            v-if="checkPermission(user,[
                                'UserPolicy:viewAny,view:affiliate',
                                'UserPolicy:create:affiliate'
                            ],false)"
                        >
                            Afiliados
                        </MenuItem>

                        <MenuItem
                            :icon="TreeViewAlt"
                            path="/admin/departments"
                            v-if="checkPermission(user,[
                                'DepartmentPolicy:viewAny,view'
                            ],false)"
                        >
                            Departamentos
                        </MenuItem>


                        <MenuItem
                            :icon="Users"
                            v-if="user.role == 'superadmin'"
                            :items="[
                                {label: 'Ver níveis', path: '/admin/levels'},
                                {label: 'Novo Nível', path: '/admin/levels/create'}
                            ]"
                        >
                            Níveis
                        </MenuItem>

                        <MenuItem
                            :icon="Users"
                            :items="[
                                {label: 'Ver todos', path: '/admin/users', show: checkPermission(user, ['UserPolicy:viewAny,view:admin'], true)},
                                {label: 'Novo Usuário', path: '/admin/users/create', show: checkPermission(user, ['UserPolicy:create:admin'], true)}
                            ]"
                            v-if="checkPermission(user,[
                                'UserPolicy:viewAny,view:admin',
                                'UserPolicy:create:admin'
                            ],false)"
                        >
                            Usuários
                        </MenuItem>

                        

                        <li 
                            class="menu-division"
                            v-if="checkPermission(user,[
                                'PaymentPolicy:viewAny,view',
                            ],false)"
                        >
                            Gestão Financeira
                        </li>

                        <MenuItem
                            :icon="Barcode"
                            :path="route('admin.payments.index')"
                            v-if="checkPermission(user,[
                                'PaymentPolicy:viewAny,view',
                            ],false)"
                        >
                            Pagamentos
                        </MenuItem>

                        <MenuItem
                            :icon="Affiliate"
                            
                            :items="[
                                {label: 'Categorias', path: '/admin/cashouts/categories'},
                                {label: 'Ver saídas', path: '/admin/cashouts'},
                            ]"

                            v-if="checkPermission(user,[
                                'CashoutPolicy:viewAny,view',
                            ],true)"
                        >
                            Saídas
                        </MenuItem>
                        
                        <MenuItem
                            :icon="Barcode"
                            :path="route('admin.affiliates.alltransfers')"
                            v-if="checkPermission(user,[
                                'TransferPolicy:viewAny,view',
                            ],true)"
                        >
                            Transferencias
                        </MenuItem>

                        <li 
                            class="menu-division"
                            v-if="user.role == 'superadmin'"
                        >
                            IP Chat
                        </li>

                        <MenuItem
                            :icon="Barcode"
                            :path="route('admin.ipchat.monitoring.fpmMonitoring')"
                            v-if="user.role == 'superadmin'"
                        >
                            PHP FPM
                        </MenuItem>
                    </ul>
                </section>
            </aside>

            <section class="right-side">
                <!--- notificação de acesso modo admin -->
                <div
                    class="tw-w-full tw-p-2 tw-bg-amber-200 tw-text-amber-950 tw-rounded-md tw-mb-2"
                    v-if="loggedAdmin"
                >
                    Acessando painel do usuario como administrador, para voltar 
                    <span
                        class="tw-font-bold tw-p-1 tw-px-2 tw-bg-blue-wc tw-text-white tw-rounded-md tw-cursor-pointer" 
                        @click="accessUserAccount"
                    >
                        clique aqui
                    </span>
                </div>
                <!--- notificação de acesso modo admin -->

                <div class="tw-w-full tw-flex tw-gap-2">
                    <span
                        class="tw-w-[60px] tw-flex lg:tw-hidden tw-justify-center tw-items-center tw-text-slate-500 tw-bg-white 
                    tw-rounded-md tw-shadow-md tw-cursor-pointer"
                        @click="toggleMobileMenu"
                    >
                        <MenuRound class="tw-w-6" />
                    </span>
                    <header 
                        class="tw-w-full tw-rounded-md tw-p-2 tw-px-3 tw-flex tw-items-center tw-shadow-md"
                        :class="searchClasses"
                    >
                        <div class="tw-flex tw-gap-3 tw-w-full" v-if="![ 'affiliate', 'client', 'agency' ].includes(user.role)">
                            <Search class="tw-w-6 tw-text-slate-400" />
                            <form class="tw-w-full" @submit.prevent="executeSearch">
                                <input
                                    type="text"
                                    class="tw-p-2 tw-outline-none tw-w-full"
                                    :class="(mode == 'dark') ? 'tw-bg-darkmode-box tw-text-slate-300' : ''"
                                    placeholder="Buscar..."
                                    v-model="searchQuery"
                                >
                            </form>
                        </div>

                        <div class="tw-flex tw-gap-3 tw-w-max">
                            <div class="tw-ml-auto tw-flex tw-relative">
                                <span
                                    @click="toggleProfile"
                                    class="profile-picture"
                                    :style="{
                                        background: `#ccc url(${user.avatar ? user.avatar : '/images/avatar.jpg'})`
                                    }"
                                >
                                    <img
                                        src="@images/avatar.jpg"
                                        v-if="!user.avatar"
                                        class="tw-p-0 tw-object-fill"
                                    >
                                </span>


                                <div
                                    class="profile-dropdown tw-top-[calc(100%_+_8px)]"
                                    :class="(mode == 'light') ? 'tw-bg-white' : 'tw-bg-darkmode-box tw-text-slate-100 tw-border-[1px] tw-border-slate-600'"
                                    v-if="profileOpened"
                                >
                                    <div
                                        class="profile-info"
                                        :class="(mode == 'dark') ? 'hover:tw-bg-[#171C22]' : 'hover:tw-bg-[#f8f8f8]'"
                                        @click="goToProfile"
                                    >
                                        <div class="tw-w-[calc(100%_-_35px)] tw-flex tw-flex-wrap tw-pl-2">
                                            <span class="tw-text-slate-600 tw-w-full tw-font-bold">{{ (user.company_name) ? user.company_name : user.name }}</span>
                                            <p class="tw-text-slate-500 tw-text-[15px]">
                                                {{ user.role }}
                                            </p>
                                        </div>
                                    </div>


                                    <ul 
                                        class="profile-menu tw-border-t-[1px] tw-pt-1"
                                        :class="(mode == 'dark') ? 'darkmode tw-border-slate-600' : 'lightmode tw-border-slate-200'"
                                    >
                                        <li v-if="!adminLogged">
                                            <Link :href="route('admin.profile')">
                                                <User class="tw-w-4 tw-mr-2" />

                                                Editar perfil
                                            </Link>
                                        </li>
                                        <li v-else-if="user.role == 'client' && checkPermission(adminLogged, [ 'UserPolicy:update:client' ], true)">
                                            <Link :href="route('admin.profile')">
                                                <User class="tw-w-4 tw-mr-2" />
                                                Editar perfil
                                            </Link>
                                        </li>

                                        <li v-else-if="user.role == 'agency' && checkPermission(adminLogged, [ 'UserPolicy:update:agency' ], true)">
                                            <Link :href="route('admin.profile')">
                                                <User class="tw-w-4 tw-mr-2" />
                                                Editar perfil
                                            </Link>
                                        </li>
                                        <li
                                            class="tw-pb-1"
                                            v-if="user.role == 'superadmin'"
                                        >
                                            <Link :href="route('admin.settings.index')">
                                                <Settings class="tw-w-4 tw-mr-2" />
                                                Configurações
                                            </Link>
                                        </li>

                                        <li
                                            class="tw-pb-1"
                                            v-if="user.role == 'agency' && user.agency_automaticcharge_feature == 1"
                                        >
                                            <Link :href="route('admin.settings.agencySettingsIndex')">
                                                <Settings class="tw-w-4 tw-mr-2" />
                                                Configurações
                                            </Link>
                                        </li>

                                        <li
                                            class="tw-pb-1"
                                            v-if="user.role == 'superadmin'"
                                        >
                                            <Link :href="route('admin.settings.notifications')">
                                                <Bell class="tw-w-4 tw-mr-2" />
                                                Notificações
                                            </Link>
                                        </li>

                                        <li
                                            class="tw-pb-1"
                                            v-if="user.role == 'agency' && user.agency_automaticcharge_feature == 1"
                                        >
                                            <Link :href="route('admin.settings.agencyNotifications')">
                                                <Bell class="tw-w-4 tw-mr-2" />
                                                Notificações
                                            </Link>
                                        </li>


                                        <li 
                                            class="tw-border-t-[1px] tw-pt-1"
                                            :class="(mode == 'dark') ? 'tw-border-slate-600' : 'tw-border-slate-200'"
                                        >
                                            <span @click="logout">
                                                <Power class="tw-w-4 tw-mr-2" />
                                                Logout
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="tw-ml-auto tw-flex tw-relative" v-if="notifications.length > 0">
                                <div
                                    class="notification-bell" 
                                    :class="(notifications.length > 0) ? 'warn' : ''"
                                    @click="toggleNotifications"
                                >
                                    <Bell class="tw-w-4" />
                                </div>

                                <section
                                    class="notifications-box"
                                    v-if="showNotifications"
                                >
                                    <header>
                                        <h1>Notificações</h1>
                                    </header>

                                    <section class="notification-wrapper scroller">
                                        <span
                                            v-if="notifications.length == 0"
                                            class="tw-flex tw-w-full tw-justify-center tw-content-center"
                                        >
                                            <p>Não há notificações.</p>
                                        </span>
                                        <Notification 
                                            v-for="(notification, key) in notifications" 
                                            :key="key" 
                                            :notification="notification"
                                        />
                                    </section>
                                </section>
                            </div>

                            <div 
                                class="tw-ml-auto tw-flex tw-relattive"
                            >
                                <div 
                                    class="mode-switch" 
                                    :class="(mode == 'light') ? 'day' : 'night'"
                                    @click="toggleMode"
                                >
                                    <Moon 
                                        class="tw-w-4"
                                        v-if="mode == 'light'"
                                    />
                                    <Sun 
                                        v-else
                                        class="tw-w-4"
                                    />
                                </div>
                            </div>
                        </div>
                    </header>
                </div>


                <section class="tw-w-full tw-flex tw-flex-wrap tw-py-6 tw-gap-4 tw-items-start tw-content-start">
                    <slot />
                </section>
            </section>
        </section>
    </section>
</template>

<style scoped>
.menu {
    @apply tw-fixed tw-top-0 tw-left-0 tw-flex tw-w-[260px] tw-h-screen tw-shadow-md tw-flex-wrap tw-items-start tw-content-start;
}

.menu.mobile {
    @apply tw-fixed tw-top-0 tw-left-0 tw-min-h-screen tw-z-30;
}

.menu.mobile.closed {
    @apply !tw-hidden;
}


.menu.mobile.opened {
    @apply !tw-flex;
}

.menu-list {
    @apply tw-flex tw-w-full tw-flex-wrap tw-gap-1 tw-overflow-y-auto;
}

.right-side {
    @apply tw-w-full lg:tw-w-[calc(100%_-_260px)] lg:tw-fixed lg:tw-right-0 lg:tw-top-0 tw-h-screen tw-overflow-y-scroll tw-p-6
}
.mode-switch{
    @apply tw-inline-flex tw-w-[35px] tw-h-[35px] tw-rounded-full tw-bg-slate-600 tw-cursor-pointer tw-justify-center tw-items-center;
}
.mode-switch.day {
    @apply tw-bg-slate-600 tw-text-white;
}
.mode-switch.night{
    @apply tw-bg-white tw-text-slate-600;
}

.profile-picture {
    @apply tw-inline-flex tw-w-[35px] tw-h-[35px] tw-bg-slate-600 tw-rounded-full
                        tw-cursor-pointer tw-overflow-hidden;

    background-size: cover !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
}
.profile-picture img {
    @apply tw-w-full;
}
.profile-dropdown {
    @apply tw-absolute tw-right-0 tw-w-[180px] tw-rounded-md tw-overflow-hidden tw-py-1 tw-shadow-lg;
    z-index:9999;
}

.profile-info {
    @apply tw-w-full tw-flex tw-items-center tw-p-4 tw-cursor-pointer;
}

.profile-menu {
    @apply tw-flex tw-flex-wrap;
}

.profile-menu li{
    @apply tw-w-full;
}


.profile-menu li a, .profile-menu li span {
    @apply tw-inline-flex tw-w-full tw-p-2 tw-pl-4  tw-text-slate-500 tw-cursor-pointer;
}

.profile-menu.lightmode li a, .profile-menu.lightmode li span{
    @apply hover:tw-bg-[#F8F8F9];
}
.profile-menu.darkmode li a, .profile-menu.darkmode li span{
    @apply hover:tw-bg-[#171C22];
}

.menu-division {
    @apply tw-relative tw-w-full tw-flex tw-my-3 tw-uppercase tw-text-[14px] tw-text-slate-400 tw-px-3
    before:tw-content-[''] before:tw-absolute before:tw-left-[-12px] before:tw-top-[50%] before:tw-w-[14px] before:tw-h-[1px]
    before:tw-bg-slate-400;
}

.notifications-box {
    @apply tw-absolute tw-top-[calc(100%_+_10px)] tw-right-0 tw-w-[300px] tw-bg-white tw-shadow-lg tw-rounded-md 
    tw-overflow-hidden tw-border-[1px] tw-border-[#BBEBFF] tw-z-[90]
}

.notifications-box .notification-wrapper{
    @apply tw-w-full tw-flex tw-flex-wrap tw-p-3 tw-gap-2 tw-max-h-[200px] tw-overflow-y-auto;
}
.notifications-box header {
    @apply tw-w-full tw-p-2  tw-text-blue-wc tw-border-b-[1px] tw-border-[#BBEBFF] tw-font-bold;
}

.notification-bell {
    @apply tw-flex tw-justify-center tw-items-center tw-w-[35px] tw-h-[35px] 
                                tw-rounded-full tw-text-red-600 tw-bg-red-300 tw-cursor-pointer tw-relative;
}

.notification-bell.warn {
    @apply before:tw-absolute before:tw-content-[''] before:tw-top-0 
    before:tw-right-0 before:tw-w-[10px] before:tw-h-[10px] before:tw-rounded-full before:tw-bg-red-600;
}

.pseudo-input {
    @apply tw-w-full tw-p-3 tw-bg-slate-100 tw-rounded-md;
}

</style>

export const currencyFormat = (amount, locale = 'pt-br', options = {
    currency: 'BRL',
    style: 'currency',
    minimumFractionDigits: 2
}) => {

    return (amount / 100).toLocaleString(locale, options)
}

export const getDateOnly = (dateTimeString: string) => {
    const splited: any = dateTimeString.split(' ')
    const dateSplited: any = splited[0].split('-').reverse().join('/')
    return dateSplited
}

export const getTimeOnly = (dateTimeString: string) => {
    const splited = dateTimeString.split(' ')
    const hourSplited = splited[1].split(':')

    return hourSplited[0] + ':' + hourSplited[1]
}

export const getAddress = async (zipcode: any) => {
    zipcode = zipcode.replace(/\D/g, '');

    return await fetch(`https://viacep.com.br/ws/${zipcode}/json/?callback=`)
        .then((data) => data.json());
}

export const isCpf = (cpf) => {
    if (cpf === undefined || cpf.length == 0) {
        return false
    }
    cpf = cpf.toString()
    cpf = cpf.replace(/[^0-9]/g, '')
    let digitoUm = 0
    let digitoDois = 0
    var i = 0
    var x = 10
    for (i = 0, x = 10; i <= 8; i++, x--) {
        digitoUm += cpf[i] * x
    }
    var i = 0
    var x = 11
    for (i = 0, x = 11; i <= 9; i++, x--) {
        if (i.toString().repeat(11) == cpf) {
            return false
        }
        digitoDois += cpf[i] * x
    }

    const calculoUm = ((digitoUm % 11) < 2) ? 0 : 11 - (digitoUm % 11)
    const calculoDois = ((digitoDois % 11) < 2) ? 0 : 11 - (digitoDois % 11)

    if (calculoUm != cpf[9] || calculoDois != cpf[10]) {
        return false
    }

    return true
}

export const maskString = (mask: string, str: string) => {
    str = str.replace(/\s/g, '')

    for (let i = 0; i < str.length; i++) {
        mask = mask.replace('#', str[i])
    }

    return mask
}

export const dateBrParser = (merda: string, withTime: boolean = false) => {
    const obj: any = new Date(merda)
    const parsed = obj.toLocaleString('pt-br')

    if (!withTime) {
        const splitedDate = parsed.split(',')
        return splitedDate[0]
    }

    return parsed.replace(',', ' às ')
}
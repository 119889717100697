<script setup>

defineProps({
    header: Object
});

const mode = inject('mode', ref('light'))

</script>

<template>
    <section 
        class="tw-w-full tw-rounded-md tw-shadow-md"
        :class="(mode == 'light') ? 'tw-bg-white' : 'tw-bg-darkmode-box'"
    >
        <header 
            class="tw-w-full tw-flex tw-flex-wrap tw-mb-3 tw-p-4 tw-justify-between"     
            v-if="header"
        >   
            <div class="tw-w-[80%]">
                <span 
                    v-if="header?.title" 
                    class="tw-w-full tw-inline-flex tw-font-semibold tw-text-[17px] tw-items-center"
                    :class="(mode == 'dark') ? 'tw-text-slate-50' : 'tw-text-slate-500'"
                >
                    {{ header?.title }}
                </span>
                <span 
                    class="tw-text-slate-400 tw-font-lighter" 
                    v-if="header?.subtitle"
                >
                    {{ header?.subtitle }}
                </span>
            </div>

            <slot name="actions" />
        </header>

        <slot />
    </section>
</template>
<script setup>
import { CloseRound } from '@vicons/material'

const props = defineProps({
    title: {
        type: String,
        default: null
    },
    smallerHeight: {
        type: Boolean,
        default: false
    }
})
const mode = inject('mode', ref('light'))

defineEmits(['closeModal'])

const extraClasses = computed(() => {
    let classes = (props.smallerHeight) ? 'tw-max-h-[75%]' : 'tw-max-h-[95%]'

    if (mode.value == 'dark') {
        classes = classes +' tw-bg-darkmode-box'
    } else {
        classes = classes + ' tw-bg-white'
    }

    return classes
})


</script>

<template>
    <Teleport to="body">
        <section class="tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-bg-black/70 tw-z-50">
            <section
                class="tw-flex max-lg:tw-w-[80%] tw-flex-col tw-rounded-md tw-text-slate-600  
                tw-fixed tw-top-[50%] tw-left-[50%] tw-translate-x-[-50%] tw-translate-y-[-50%] 
                tw-shadow-md
            "
                :class="extraClasses"
                v-bind="$attrs"
            >
                <section
                    class="tw-relative tw-w-full tw-h-full tw-overflow-y-auto tw-overflow-x-hidden scroller" 
                    ref="myDiv"
                    id="myDiv"
                >
                    <header 
                        v-if="title"
                        class="tw-flex tw-w-full tw-justify-between tw-border-b-[1px] tw-border-slate-300 
                    tw-p-4 tw-items-center"
                    >
                        <h4 
                            class="tw-font-[18px] tw-font-medium"
                            :class="(mode == 'dark') ? 'tw-text-slate-300' : 'tw-text-slate-600'"
                        >
                            {{ title }}
                        </h4>

                        <span
                            class="tw-inline-flex tw-w-[40px] tw-aspect-square tw-rounded-md hover:tw-bg-slate-200 
                        tw-justify-center tw-items-center tw-cursor-pointer" 
                            @click="$emit('closeModal')"
                        >
                            <CloseRound class="tw-w-5 tw-text-slate-600" />
                        </span>
                    </header>

                    <!--- content -->
                    <section 
                        class="tw-w-full tw-flex tw-flex-wrap tw-p-4 modal-content"
                        :class="mode"
                    >
                        <slot />
                    </section>
                </section>
            </section>
        </section>
    </Teleport>
</template>

<style scoped>
.modal-content.dark {
    @apply !tw-text-slate-300;
}
</style>

export const checkPermission = (user, permissions, checkAll = true) => {
    if (user.role == 'superadmin') {
        return true
    }

    const permissionsUser: any = []

    user.levels.map((level: any) => {
        level.permissions.map((permission: string) => {
            permissionsUser.push(permission)
        })
    })

    if (checkAll) {
        //@ts-ignore
        return permissions.every(value => permissionsUser.includes(value))
    }

    //@ts-ignore
    return permissions.some(value => permissionsUser.includes(value))
}
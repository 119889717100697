<script setup lang="ts">
import { router, usePage } from '@inertiajs/vue3'
import { dateBrParser } from '@/Helpers/Utils'
const user = computed(() => usePage().props.auth.user)

const props = defineProps({
    notification: {
        type: Object,
        default: null
    }
})
/*
metadata: {
    goto: 'transfers'
}
*/
const entities:any = {
    transfers: 'admin.affiliates.alltransfers',
    ticket: 'admin.tickets.show',
    clientupd: 'admin.clients.edit',
    agencyupd: 'admin.agencies.edit',
    schedules: 'admin.schedules',
    payments: 'admin.payments.index',
    paymentsClient: 'admin.client.clientPayments'
}

const goToAction = () => {
    if (props.notification.metadata) {
        
        let path = props.notification.metadata.goto

        if (path == 'payments') {
            if (user.value.role == 'agency' || user.value.role == 'client') {
                path = 'paymentsClient'
            } else {
                path == 'payments'
            }
        }

        if ('entity_reference' in props.notification.metadata) {
            const params = props.notification.metadata.entity_reference
            console.log(route(entities[path], params))
            console.log(params)
            router.visit(route(entities[path], params))
        } else {
            
           router.visit(route(entities[path]))
        }
    }
}

const handleAction = () => {
    window.axios.post(route('admin.view.notification', {
        notification: props.notification.id
    })).then(() => {
        goToAction()
    })
}
</script>

<template>
    <div
        class="notification"
        @click="handleAction"
    >
        <span>{{ dateBrParser(notification.created_at, true) }}</span>
        <p>{{ notification.notification }}</p>
    </div>
</template>

<style scoped>
.notification{
    @apply tw-w-full tw-p-2 tw-rounded-md tw-bg-slate-100 tw-text-slate-500 tw-text-[14px] tw-cursor-pointer;
}

.notification span{
    @apply tw-w-full tw-font-bold tw-mb-2;
}
</style>
<script setup>
import { Link } from '@inertiajs/vue3'
import { ChevronRight } from '@vicons/tabler'

const props = defineProps({
    items: Object,
    path: String,
    action: String,
    icon: {
        type: Object,
        default: null
    }
})

const subOpened = ref(false)
const toggleSubmenu = () => {
    subOpened.value = (subOpened.value) ? false : true
}

const mode = inject('mode', ref('light'))

const filteredItems = ref([]);
onBeforeMount(() => {
    if(props.items) {
        filteredItems.value = props.items.filter(item => !('show' in item) || item.show === true)
    }
})
</script>

<template>
    <li
        class="menu-item sub tw-overflow-hidden"
        :class="(mode == 'dark') ? 'darkmode' : 'lightmode'"
        v-if="items"
    >
        <div
            class="sub-wrapper"
            @click="toggleSubmenu"
        >
            <div class="tw-flex tw-gap-2">
                <component
                    :is="icon"
                    class="menu-icon"
                />
                <span>
                    <slot />
                </span>
            </div>

            <ChevronRight
                class="tw-w-4"
                :class="(subOpened) ? 'turn' : ''"
            />
        </div>
        <ul
            class="tw-w-full submenu"
            v-if="subOpened"
        >
            <li
                class="submenu-item"
                :class="(mode == 'dark') ? 'darkmode' : 'lightmode'"
                v-for="(item, key) in filteredItems"
                :key="`item_`+key"
            >
                <Link
                    :href="item.path"
                >
                    {{ item.label }}
                </Link>
            </li>
        </ul>
    </li>

    <li
        class="menu-item"
        :class="(mode == 'dark') ? 'darkmode' : 'lightmode'"
        v-else
    >
        <Link 
            v-if="path"
            :href="path"
        >
            <component
                :is="icon"
                class="menu-icon"
            />
            <span><slot /></span>
        </Link>

        <span 
            class="item-btn" 
            v-else
            @click="$emit(action)"
        >
            <component
                :is="icon"
                class="menu-icon"
            />
            <span><slot /></span>
        </span>
    </li>
</template>

<style scoped>

.menu-item{
    @apply tw-flex tw-flex-wrap tw-w-full tw-rounded-md;
}
.menu-item.darkmode {
    @apply hover:!tw-bg-[#171C22];
}
.menu-item.lightmode {
    @apply hover:!tw-bg-[#F8F8F9];
}
.menu-icon{
    @apply tw-w-4;
}

.menu-item > a,
.menu-item > .item-btn {
    @apply tw-flex tw-gap-2 tw-w-full tw-p-2 tw-px-3 tw-text-slate-500 tw-cursor-pointer;
}

.menu-item.sub {
    @apply tw-text-slate-500 tw-cursor-pointer tw-gap-2 hover:tw-bg-[#F8F8F9];
}

.menu-item.sub .sub-wrapper {
    @apply tw-w-full tw-p-2 tw-px-3 tw-flex tw-justify-between tw-rounded-md;
}

.submenu {
    @apply tw-flex tw-flex-wrap;
}

.submenu-item {
    @apply tw-flex tw-flex-wrap tw-w-full tw-mb-1;
}
.submenu-item.darkmode {
    @apply hover:!tw-bg-[#13171C];
}
.submenu-item.lightmode {
    @apply hover:!tw-bg-[#EAEAEC];
}
.submenu-item a{
    @apply tw-p-2 tw-w-full tw-px-3 tw-text-slate-500 tw-relative tw-pl-9
    before:tw-w-[6px] before:tw-h-[6px] before:tw-bg-slate-600 before:tw-rounded-full
    before:tw-absolute before:tw-left-[15px] before:tw-top-[calc(50%_-_3px)];
}

.submenu-item a:before{
    content: '';
}

.turn {
    @apply tw-rotate-90;
}
</style>

<script setup>
import { Link, usePage } from '@inertiajs/vue3'
import { Clock, Menu2 } from '@vicons/tabler'
import { WhatsappFilled } from '@vicons/material'
import { FacebookF, Instagram, Youtube, Google } from '@vicons/fa'

const isFixed = ref(false)
const routeName = computed(() => usePage().props.routeName)
const numbersActive = ref(false)

const toggleNumbers = () => {
    numbersActive.value = !numbersActive.value
}

const handleScroll = () => {
    //window.innerHeight
    isFixed.value = window.scrollY > 300
}
onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll)
})
    
onMounted(() => {
    window.addEventListener('scroll', handleScroll)
})

const mobileMenuOpened = ref(false)
const toggleMobileMenu = () => {
    mobileMenuOpened.value = !mobileMenuOpened.value
}


const menuBackground = computed(() => {
    if (isFixed.value === true) {
        return 'tw-bg-white tw-shadow-md'
    } else if (routeName.value !== 'home') {
        return 'dark'
    }
})

const currentHour = new Date().getHours()

// Determinar a saudação com base na hora atual
const saudacao = ref('');
if (currentHour >= 5 && currentHour < 12) {
    saudacao.value = 'Bom dia'
} else if (currentHour >= 12 && currentHour < 18) {
    saudacao.value = 'Boa tarde'
} else {
    saudacao.value = 'Boa noite'
}
</script>

<template> 
    <header class="tw-w-full tw-flex tw-flex-wrap tw-top-0 tw-left-0 tw-z-[100]"
        :class="(isFixed) ? 'tw-fixed' : 'tw-absolute'">
        <section class="tw-hidden min-[800px]:tw-flex tw-w-full tw-bg-blue-wc tw-text-white"
            :class="(isFixed) ? '!tw-hidden' : ''">
            <div class="centralized tw-flex tw-justify-between">
                <div class="tw-w-max">
                    <span class="header-info tw-inline-flex tw-font-bold">
                        <Clock class="tw-w-4" />
                        Seg-Sex 09:00 às 18:00
                    </span>

                    <span class="header-info tw-font-bold tw-hidden min-[1000px]:tw-inline-flex">
                        <WhatsappFilled class="tw-w-4" />
                        +55 24 98821-6038 | Comercial
                    </span>
                </div>

                <ul class="socials">
                    <li>
                        <a href="https://www.facebook.com/WhatsCompany/">
                            <FacebookF class="tw-w-3" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/whatscompany.oficial/">
                            <Instagram class="tw-w-4" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/@whatscompanyti6188">
                            <Youtube class="tw-w-4" />
                        </a>
                    </li>
                    <li>
                        <a href="https://g.co/kgs/xcVDYR">
                            <Google class="tw-w-4" />
                        </a>
                    </li>
                </ul>
            </div>
        </section>
        <section class="tw-w-full tw-py-4 tw-relative menu-stripe" :class="menuBackground">
            <div class="centralized tw-flex tw-justify-between tw-relative">
                <Link href="/">
                    <h1 class="logo">
                        <span class="tw-text-blue-wc">Whats.</span>
                        <span class="tw-text-green-wc">Company</span>
                    </h1>
                </Link>
                
                <ul class="menu tw-hidden min-[1200px]:tw-flex">
                    <li>
                        <a href="/" :class="(isFixed) ? '!tw-text-slate-500 hover:!tw-text-blue-wc' : ''">Home</a>
                    </li>
                    <li>
                        <a href="/#benesses" :class="(isFixed) ? '!tw-text-slate-500 hover:!tw-text-blue-wc' : ''">
                            Sistemas
                        </a>
                    </li>
                    <li>
                        <a href="/#partner" :class="(isFixed) ? '!tw-text-slate-500 hover:!tw-text-blue-wc' : ''">
                            Agencias | Afiliados
                        </a>
                    </li>
                    <li>
                        <a href="/#contact" :class="(isFixed) ? '!tw-text-slate-500 hover:!tw-text-blue-wc' : ''">
                            Contato
                        </a>
                    </li>
                    <li>
                        <Link href="/login" :class="(isFixed) ? '!tw-text-slate-500 hover:!tw-text-blue-wc' : ''">
                            Login
                        </Link>
                    </li>
                </ul>



                <div class="mobile-menu">
                    <span class="burguer" @click="toggleMobileMenu">
                        <Menu2 class="tw-w-4" />
                    </span>
                </div>
            </div>


            <Transition name="bounce">
                <ul class="mobile-menu-list" v-if="mobileMenuOpened">
                    <li class="active"><a href="/#home">Home</a></li>
                    <li><a href="/#benesses">Sistemas</a></li>
                    <li><a href="/#partner">Agencias | Afiliados</a></li>
                    <li><a href="/#contact">Contato</a></li>
                    <li>
                        <Link href="/login">Login</Link>
                    </li>
                </ul>
            </Transition>
        </section>
    </header>
    <slot />


    <section class="tw-w-full tw-flex tw-bg-slate-100">
        <div class="centralized tw-py-8">
            <ul class="client-logos tw-justify-center">
                <li><img src="images/client-01.png" /></li>
                <li><img src="images/client-02.png" /></li>
                <li><img src="images/client-04.png" /></li>
            </ul>
        </div>
    </section>


    <div class="wa-box">
        <transition name="fade">
            <section class="wa-box-numbers" v-if="numbersActive">
                <header class="numbers-header">
                    <div class="header-logo">
                        <img src="images/logo_box.jpg" />
                    </div>

                    <div class="header-content">
                        <h2>{{ saudacao }}, como vai?</h2>
                        <span>Escolha o departamento desejado</span>
                    </div>
                </header>

                <section class="numbers-content">
                    <span class="tw-w-full tw-text-slate-400 tw-text-[13px] tw-mb-3 tw-flex">
                        A equipe normalmente responde em poucos minutos.
                    </span>
                    
                    <a href="https://wa.me/5524988216038?text=Ol%C3%A1" target="_blank">
                        <div class="number-item">
                            <div class="number-logo">
                                <img src="images/logo_box.jpg" />
                            </div>
                            <div class="number-content">
                                <span>Comercial</span>
                                <p>5524988216038</p>
                            </div>
                        </div>
                    </a>

                    <a href="https://wa.me/5524988177274?text=Ol%C3%A1" target="_blank">
                        <div class="number-item">
                            <div class="number-logo">
                                <img src="images/logo_box.jpg" />
                            </div>

                            <div class="number-content">
                                <span>Suporte</span>
                                <p>5524988177274</p>
                            </div>
                        </div>
                    </a>

                </section>
            </section>
        </transition>

        <transition name="fadebox">
            <div class="wa-box-call" v-if="!numbersActive">
                <span>Precisa de ajuda?</span>
                <p>Converse connosco</p>
            </div>
        </transition>

        <img 
            src="images/whatsapp.png"
            class="tw-cursor-pointer"
            @click="toggleNumbers()" 
        />
    </div>

    <footer class="tw-w-full tw-bg-[#232323]">
        <div class="centralized tw-flex tw-flex-wrap min-[1000px]:!tw-flex-nowrap tw-py-[80px]">

            <section class="tw-w-full tw-border-b-[1px] tw-border-white/30 tw-pb-8 tw-mb-8
            min-[1000px]:!tw-w-[50%] min-[1000px]:tw-border-0 min-[1000px]:tw-pb-0 min-[1000px]:tw-mb-0">
                <h2 class="footer-title tw-text-blue-wc">WhatsCompany Sistemas LTDA</h2>
                <span class="tw-w-full tw-text-[#ccc] tw-font-bold">CNPJ: 11.460.809/0001-12</span>

                <p class="tw-text-[#ccc] tw-w-full tw-mt-8">
                    Rua Dr Paulo Hervé, 1.400<br />
                    2º andar - Bingen - Petrópolis/RJ<br />
                    Aldeia Shopping - 25.665-510
                </p>
            </section>

            <section
                class="tw-w-full tw-border-b-[1px] tw-border-white/30 tw-pb-8 tw-mb-8 
            min-[1000px]:!tw-w-[25%] min-[1000px]:tw-border-0 min-[1000px]:tw-pb-0 min-[1000px]:tw-mb-0 footer-section">
                <h2 class="footer-title tw-text-green-wc">Sistemas</h2>
                <ul>
                    <li><a href="/multiatendimento">Multi-Atendimento</a></li>
                    <li><a href="/box">Box para sites</a></li>
                    <li><a href="/api-whatsapp">API developers</a></li>
                </ul>
            </section>

            <section class="tw-w-full min-[1000px]:!tw-w-[25%] footer-section">
                <h2 class="footer-title tw-text-[#007FFF]">Para você</h2>

                <ul>
                    <li><a href="/whitelabel">White Label</a></li>
                    <li><a href="/pravc">Afiliados</a></li>
                    <li><a href="/login">Consultar NF-e</a></li>
                    <li><a href="/termos">Termos de Uso</a></li>
                </ul>
            </section>

        </div>

        <section class="tw-w-full tw-bg-[#343434] tw-py-8 tw-flex tw-justify-center tw-text-white">
            <span>WhatsCompany | Todos os direitos reservados. 2016 - 2024</span>
        </section>
    </footer>
</template>


<style scoped>
.fade-enter-active {
    animation: fade-show .5s forwards;
}
.fade-leave-active {
    animation: fade-show .5s reverse;
}

.fadebox-enter-active {
    animation: fade-showbox .5s forwards;
}
.fadebox-leave-active {
    animation: fade-showbox .5s reverse;
}

@keyframes fade-show {
  0% {
    @apply tw-bottom-[30%] tw-opacity-0 tw-pointer-events-none
  }
  100% {
    @apply tw-bottom-[100%] tw-opacity-100 tw-pointer-events-auto
  }
}

@keyframes fade-showbox {
  0% {
    @apply tw-bottom-[-30px] tw-opacity-0 tw-pointer-events-none
  }
  50% {
    @apply tw-opacity-0;
  }
  100% {
    @apply tw-bottom-[20px] tw-opacity-100 tw-pointer-events-auto
  }
}

.wa-box {
    @apply tw-fixed tw-bottom-[0px] tw-right-[-20px] tw-w-[150px];
}

.wa-box-call {
    @apply tw-absolute tw-w-max tw-p-2 tw-px-4 tw-right-[80%] tw-bottom-[20px] tw-bg-white tw-rounded-md;
}
.wa-box-call span{
    @apply tw-text-[14px] tw-font-bold tw-text-black;
}
.wa-box-call p{
    @apply tw-text-[13px] tw-font-bold tw-text-slate-500;
}
.wa-box img {
    @apply tw-w-full;
}

.wa-box .wa-box-numbers {
    @apply tw-bg-[#2B2B2B] tw-absolute tw-right-[45px] tw-rounded-md tw-overflow-hidden tw-w-[350px] tw-bottom-[100%];
}

.wa-box-numbers .numbers-header{
    @apply tw-flex tw-gap-4 tw-w-full tw-p-3 tw-bg-[#282626];
}

.wa-box-numbers .numbers-header .header-logo {
    @apply tw-w-[60px] tw-rounded-md tw-overflow-hidden;
}
.wa-box-numbers .numbers-header .header-content {
    @apply tw-w-full tw-text-white;
}
.wa-box-numbers .numbers-header .header-content h2 {
    @apply tw-font-bold;
}
.wa-box-numbers .numbers-header .header-content span{
    @apply tw-text-[13px];
}

.wa-box-numbers .numbers-header .header-logo img{
    @apply tw-w-full;
}

.wa-box-numbers .numbers-content {
    @apply tw-w-full tw-p-4;
}

.numbers-content .number-item{
    @apply tw-w-full tw-flex tw-gap-4 tw-mb-3 tw-bg-[#444242] tw-p-2 tw-rounded-md tw-border-l-[3px] tw-border-green-wc hover:tw-bg-[#282626] 
    tw-cursor-pointer;
}

.numbers-content .number-item .number-logo{
    @apply tw-w-[50px] tw-rounded-full tw-border-[3px] tw-border-green-wc tw-overflow-hidden;
}
.numbers-content .number-item .number-logo img{
    @apply tw-w-full;
}

.numbers-content .number-item .number-content {
    @apply tw-text-white tw-text-[14px] tw-flex tw-items-center tw-flex-wrap tw-content-center;
}

.numbers-content .number-item .number-content span{
    @apply tw-w-full
}

.numbers-content .number-item .number-content p{
    @apply tw-text-[13px]
}
.logo {
    @apply tw-text-[30px] tw-font-bold;
    font-family: Arial, Helvetica, sans-serif;
}

.header-info {
    @apply tw-gap-2 tw-border-[1px] tw-border-b-0 tw-border-t-0 tw-border-white/30 
    tw-p-3 tw-px-3;
}

.socials {
    @apply tw-flex
}
.socials li {
    @apply tw-h-full tw-border-[1px] tw-border-b-0 tw-border-t-0 tw-border-l-0 tw-border-white/30 tw-flex tw-justify-center;
}

.socials li a{
    @apply tw-text-white tw-px-3 tw-flex tw-items-center
}

.menu-stripe.dark {
    @apply tw-bg-black/60 tw-relative 
    before:tw-absolute before:tw-top-[100%] before:tw-left-0 before:tw-w-[40%] before:tw-h-[4px] before:tw-bg-blue-wc 
    after:tw-absolute after:tw-top-[100%] after:tw-right-0 after:tw-w-[60%] after:tw-h-[4px] after:tw-bg-green-wc
}

.menu {
    @apply tw-gap-12 tw-items-center;
}

.menu li a{
    @apply tw-text-white hover:tw-text-green-wc hover:tw-border-green-wc tw-border-b-[1px] tw-border-transparent tw-pb-4;
}

.menu li.active a{
    @apply tw-text-green-wc tw-border-green-wc
}


.mobile-menu {
    @apply tw-absolute tw-right-[15px] tw-top-[50%] min-[1200px]:!tw-hidden
}

.mobile-menu .burguer {
    @apply tw-w-[40px] tw-aspect-square tw-rounded-md tw-bg-white tw-flex tw-justify-center tw-items-center 
    tw-cursor-pointer hover:tw-bg-slate-100;
    transform: translateY(-50%)
}

.mobile-menu-list {
    @apply tw-w-full tw-absolute tw-top-[100%] tw-bg-white tw-flex min-[1200px]:!tw-hidden tw-flex-wrap tw-shadow-md
}

.mobile-menu-list li {
    @apply tw-w-full tw-flex tw-border-b-[1px] tw-border-slate-200 last:tw-border-0
}

.mobile-menu-list li a{
    @apply tw-w-full tw-p-4 tw-text-center tw-text-slate-500 hover:tw-text-blue-wc
}

.mobile-menu-list li.active a {
    @apply tw-text-blue-wc
}

.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}



/* footer */
.client-logos{
    @apply tw-w-full tw-flex tw-gap-3 tw-overflow-hidden
}

.client-logos li{
    @apply tw-w-1/4 tw-justify-center tw-flex
}

.footer-title{
    @apply tw-w-full tw-font-bold tw-text-[22px];
}

.footer-section ul{
    @apply tw-flex tw-w-full tw-flex-wrap tw-mt-8
}

.footer-section ul li{
    @apply tw-flex tw-w-full tw-mb-4
}

.footer-section ul li a{
    @apply tw-text-white hover:tw-text-blue-wc
}

</style>